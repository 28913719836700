<template>
  <div class="max-w-4xl w-full px-2 text-center">
    <section
      data-testid="greeting-section"
      class="section"
    >
      <h1 class="text-4xl text-black font-semibold mb-2">
        คุยกับเพื่อนใหม่ในบรรยากาศบาร์ทิพย์
      </h1>
      <h3 class="text-paragraph-gray">
        มาแชร์เรื่องราวสุข ทุกข์ เรื่อยเปื่อยกับคนไม่รู้จักกัน
      </h3>
      <img
        draggable="false"
        src="/bartender-greet.png"
        class="max-h-80 mt-4"
      >
      <button class="mx-auto w-44">
        <img
          data-testid="log-in-upper"
          draggable="false"
          src="/nav/log-in.png"
          @click="$store.setShowLoginModal(true)"
        >
      </button>
      <div class="flex flex-col items-center pt-1">
        <!-- <p class="text-lg">
          or <NuxtLink to="/app" data-testid="non-login-join-upper" class="underline text-paragraph-gray cursor-pointer">
            Join
            anonymously
          </NuxtLink>
        </p> -->
        <p class="mt-2 text-sm">
          By continuing, you agree to our <a
            class="underline text-paragraph-gray"
            href="/privacy"
            target="_blank"
          >Terms & Policy</a>
        </p>
      </div>
    </section>
    <visit-count />
    <features />
    <section data-testid="convince-section">
      <h2 class="font-bold mb-2">
        Somebody is waiting for you . . .
      </h2>
      <p class="font-semibold text-xl">
        Don't make them wait too long . . .
      </p>
      <button class="mx-auto w-44">
        <img
          data-testid="log-in-mid-page"
          draggable="false"
          src="/nav/log-in.png"
          @click="$store.setShowLoginModal(true)"
        >
      </button>
      <!-- <div class="flex flex-col items-center pt-1">
        <p class="text-lg">
          or <NuxtLink to="/app" data-testid="non-login-join-mid-page" class="underline text-paragraph-gray cursor-pointer">
            Join
            anonymously
          </NuxtLink>
        </p>
      </div> -->
      <div class="grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mt-16 lg:mt-12">
        <div>
          <div class="flex justify-evenly">
            <p class="font-bold text-2xl -rotate-30">
              Discord Login
            </p>
            <p class="font-bold text-2xl rotate-30">
              Perks !
            </p>
          </div>
          <div class="relative">
            <img
              draggable="false"
              src="https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/accessories/hats/discord-1.png"
              class="perks-hat"
            >
            <img
              draggable="false"
              src="/landing/discord-perks.png"
              alt="discord-perks"
              class="max-w-md mx-auto w-full"
            >
          </div>
        </div>
        <div class="mt-3 lg:mt-0 max-w-md text-left mx-auto">
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              Get a special hat & deals
            </p>
          </div>
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              Meet people in DrinksOnMe community
            </p>
          </div>
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              Access to "Match by drink" feature and more exclusive features in the future !
            </p>
          </div>
        </div>
      </div>
    </section>
    <moments />
    <partner-with-us />
    <social />
  </div>
</template>

<script>
import VisitCount from '~/components/landing/VisitCount.vue'
import Features from '~/components/landing/Features.vue'
import Moments from '~/components/landing/Moments.vue'
import PartnerWithUs from '~/components/landing/PartnerWithUs.vue'
import Social from '~/components/landing/Social.vue'

export default {
  components: {
    VisitCount,
    Features,
    Moments,
    PartnerWithUs,
    Social,
  },
  computed: {
    pageLoading() {
      return this.$store.pageLoading
    },
    user() {
      return this.$store.user
    },
    enableAppleLogin() {
      return false
    },
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.$router.push({ path: '/app' })
      }
    },
  },
  mounted() {
    if (this.user) {
      this.$router.push({ path: '/app' })
    }
  },
}
</script>

<style lang="scss">
section {
  @apply py-14 flex flex-col items-center;
}

.perks-hat {
  position: absolute;
  height: 48px;
  top: -16px;
  left: calc(50% - 16px);

  @media only screen and (max-width: 1024px) {
    top: -20px;
  }
}
</style>
