<template>
  <section data-testid="features-section">
    <h2 class="font-bold mb-12">
      Inside DrinksOnMe virtual bar, you can
    </h2>
    <div class="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start w-full">
      <feature-card
        number="1"
        class="mt-6 lg:mt-0"
        image-src="/landing/feature-chat.gif"
        desc="Chat with random Strangers"
        extra-desc="You can converse either one on one or in a group (up to 5 persons)"
      />
      <feature-card
        number="2"
        class="mt-6 lg:mt-0"
        image-src="/landing/feature-adjust-sound.gif"
        desc="Adjust bar's ambient"
        extra-desc="Adjust bar's ambient, music, and volume to your preference"
      />
      <feature-card
        number="3"
        class="mt-6 lg:mt-0"
        image-src="/landing/feature-starter.gif"
        desc="Play with our converstaion starters"
        extra-desc="Our bartender can help you facilitate the conversation with cocktails and questions"
      />
    </div>
  </section>
</template>

<script>
import FeatureCard from '~/components/landing/FeatureCard.vue'

export default {
  components: {
    FeatureCard,
  },
}
</script>
