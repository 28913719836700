<template>
  <section data-testid="moment-section">
    <h2 class="font-bold mb-12">
      #DrinksonmeMoment
    </h2>
    <testimonial-card
      v-show="activeTestimonial === 0"
      username="PowrPii"
    >
      <p class="text-lg">
        The encouragement I’ve gotten from P’Gam made me realise that I have potential to study and become a better person.
      </p>
      <br>
      <p class="text-lg">
        Thank you for brightening up my day :) Thank you all the creators for such an amazing website !! ❤️☕
      </p>
    </testimonial-card>
    <testimonial-card
      v-show="activeTestimonial === 1"
      username="Sunnysideuptamago"
    >
      <p class="text-lg">
        It was an ordinary gloomy day of my life. I was trying to get through this downside of my 20’s. Somehow, I had discovered Drinksonme and gave it a try.
      </p>
      <br>
      <p class="text-lg">
        It was one of the best moment ever of this year. I feel thankful to everyone who put their effort on creating this wholesome cyber-safe space.
      </p>
    </testimonial-card>
    <testimonial-card
      v-show="activeTestimonial === 2"
      username="Rock"
    >
      <p class="text-lg">
        Thanks for a good safe zone on my stressful days. Thank you to everyone who spoke to me both on the website and on Discord.
      </p>
      <br>
      <p class="text-lg">
        And thank you to both creators, if there were no flippers that day There probably wouldn't be a good website like this.
      </p>
    </testimonial-card>
    <div class="grid gap-2 grid-cols-3 mt-4">
      <button
        class="w-3 h-3 rounded-full"
        :class="activeTestimonial === 0 ? 'bg-gray-600': 'bg-gray-300'"
        @click="activeTestimonial = 0"
      />
      <button
        class="w-3 h-3 rounded-full"
        :class="activeTestimonial === 1 ? 'bg-gray-600': 'bg-gray-300'"
        @click="activeTestimonial = 1"
      />
      <button
        class="w-3 h-3 rounded-full"
        :class="activeTestimonial === 2 ? 'bg-gray-600': 'bg-gray-300'"
        @click="activeTestimonial = 2"
      />
    </div>
    <img
      draggable="false"
      src="/landing/moment-full-bar.png"
      class="max-w-xl w-full mt-8"
    >
  </section>
</template>

<script>
import TestimonialCard from '~/components/landing/TestimonialCard.vue'

export default {
  components: {
    TestimonialCard,
  },
  data() {
    return {
      activeTestimonial: 0,
    }
  },
}
</script>
