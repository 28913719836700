<template>
  <section data-testid="social-section">
    <h2 class="font-bold mb-3">
      Meet us here
    </h2>
    <h3 class="font-semibold mb-8">
      20k ++ Community members on Discord
    </h3>
    <div class="flex justify-evenly">
      <a
        class="cursor-pointer w-12 mx-3"
        target="_blank"
        href="https://discord.gg/EJeRUY4WBn"
      ><img
        draggable="false"
        src="~/public/assets/images/social/discord.png"
      ></a>
      <a
        class="cursor-pointer w-12 mx-3"
        target="_blank"
        href="https://twitter.com/Drinksonme_Bar"
      ><img
        draggable="false"
        src="~/public/assets/images/social/twitter.png"
      ></a>
      <a
        class="cursor-pointer w-12 mx-3"
        target="_blank"
        href="https://www.instagram.com/drinksonme.live"
      ><img
        draggable="false"
        src="~/public/assets/images/social/ig.png"
      ></a>
      <a
        class="cursor-pointer w-12 mx-3"
        target="_blank"
        href="https://www.tiktok.com/@namototae?_t=8VBeII2bFMv&_r=1"
      ><img
        draggable="false"
        src="~/public/assets/images/social/tiktok.png"
      ></a>
    </div>
  </section>
</template>
