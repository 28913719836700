<template>
  <div class="relative w-full max-w-xl bg-white px-4 lg:px-16 flex justify-center pt-20 h-[400px] rounded-2xl shadow-md">
    <div class="text-left">
      <slot />
    </div>
    <p class="absolute w-full text-center bottom-8 text-lg font-semibold">
      {{ username }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
      required: true,
    },
  },
}
</script>
