<template>
  <section data-testid="partner-with-us-section">
    <h2 class="font-bold mb-3">
      Partner with us
    </h2>
    <h3 class="text-paragraph-gray font-semibold text-center">
      Promote your business (Bar,Restaurant,cafe) or any products/services to reach 150k++ users every month (Each user spends ~10 minutes on our site)
    </h3>
    <div class="relative mt-11 w-full flex flex-col items-center justify-center">
      <div class="absolute flex left-0 top-16 desktop-only">
        <p class="w-48 text-center">
          Customize background and feature your business
        </p>
        <img
          draggable="false"
          class="h-7"
          src="/landing/right-arrow.png"
        >
      </div>
      <img
        draggable="false"
        src="/landing/sponsor-example.png"
        alt="sponsor-example"
        class="max-w-md w-full shadow-md"
      >
      <div class="absolute flex right-0 bottom-7 desktop-only">
        <img
          draggable="false"
          class="h-7 rotate-180"
          src="/landing/right-arrow.png"
        >
        <p class="w-48 text-center">
          Advertisement for your product / service
        </p>
      </div>
      <p class="w-48 text-center mobile-only mt-6">
        Customize background and feature your business
      </p>
      <p class="w-48 text-center mobile-only mt-6">
        Advertisement for your product / service
      </p>
    </div>
    <a
      href="https://page.line.me/?accountId=drinksonme"
      target="_blank"
    >
      <dom-button class="mt-11 py-6 w-80">
        <p class="font-bold text-2xl">
          Contact Us
        </p>
      </dom-button>
    </a>
  </section>
</template>

<script>
import DomButton from '~/components/DomButton.vue'

export default {
  components: {
    DomButton,
  },
}
</script>
