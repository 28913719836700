<template>
  <div class="flex flex-col items-center text-center px-2 w-64">
    <div class="bg-secondary rounded-full w-9 h-9 flex justify-center items-center">
      <p class="font-semibold">
        {{ number }}
      </p>
    </div>
    <img
      :src="imageSrc"
      class="w-48 mt-4"
    >
    <h3 class="font-semibold my-2">
      {{ desc }}
    </h3>
    <p class="text-paragraph-gray">
      {{ extraDesc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    extraDesc: {
      type: String,
      required: true,
    },
  },
}
</script>
