<template>
  <section data-testid="visit-count-section">
    <h2 class="font-bold mb-12">
      More than 300,000 people have visited !
    </h2>
    <img
      draggable="false"
      src="/landing/counter-example.png"
      alt="counter"
      class="max-w-4xl w-full desktop-only"
    >
    <img
      draggable="false"
      src="/landing/mobile-counter-example.png"
      alt="counter"
      class="max-w-2xl w-full mobile-only"
    >
  </section>
</template>
